
.services-menu.user-menu .accordion .accordion-item {
    background-color: #171a26;
    color: white;
}
.services-menu.user-menu .accordion .accordion-item .accordion-button {
    background-color: #171a26;
    font-size: 1.2rem;
    color: white;
    border-bottom: 1px solid #414345!important;
    padding: 1rem 0;
}
.services-menu.user-menu .accordion .accordion-item .accordion-button:after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}
.services-menu.user-menu .accordion .accordion-item .accordion-button:hover {
    background-color: #1f2230;
    color: #a9d3ff;
}
.services-menu.user-menu .accordion .accordion-item .accordion-body {
    padding-top: 0;
}

.mobile .services .user-form {
    border-color: #dc3545!important;
}
.mobile .services .user-form.border-0 {
    border: 0!important;
}
.mobile .services button[type=submit], button.btn-services-gold, a.btn-services-gold {
    max-width: unset;
    background-color: #D4AF37;
    border-color: #FFD700;
    color: #4e3c00;
}
.mobile .services button[type=submit]:hover, button.btn-services-gold:hover, a.btn-services-gold:hover, button.selected-and-active {
    background-color: #FFD700;
    border-color: #D4AF37;
    color: #4e3c00;
}

.mobile .services .modal-content {
    border: 1px solid #dc3545;
}
.mobile .services .modal-header { border-bottom: 1px solid #dc3545; }
.mobile .services .modal-footer { border-top: none; padding: 0 1.5rem 1.5rem 0; }
.mobile .services .modal-footer button {
    background-color: #D4AF37;
    border-color: #FFD700;
    color: #4e3c00;
}
.mobile .services .modal {
    background-color: rgba(0, 0, 0, 0.6);
}

.mobile .services .comments .btn.btn-send-comment {
    padding: 0;
    font-size: 1.1rem;
    margin-left: 2px;
    color: #D4AF37;
}
.mobile .services .comments .btn.btn-send-comment:hover {
    color: #FFD700;
}
.mobile .services .comments .btn.btn-show-comments {
    font-size: 0.8rem;
    padding: 4px 10px;
}

.mobile .services .comments .comment-me {
    font-size: 0.8rem;
    text-align: right;
    margin-bottom: 7px;
}
.mobile .services .comments .comment-not-me {
    font-size: 0.8rem;
    text-align: left;
    margin-bottom: 7px;
}
.mobile .services .comments .comment-me div {
    background-color: #dc3545;
    padding: 6px 12px;
    border-radius: 10px;
    max-width: 80%;
}
.mobile .services .comments .comment-not-me div {
    background-color: gray;
    padding: 6px 12px;
    border-radius: 10px;
    max-width: 80%;
}
.mobile .services .comments .sender-pic, .ratings-and-reviews .profile-pic {
    width: 1.5rem;
    height: 1.5rem;
}
