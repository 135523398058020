.mobile .sign-in-form, .mobile .register-form, .mobile .reset-password-form {
    background-color: #171a26;
    width: 100%;
    max-width: 600px;
}

.mobile .sign-in-form input, .mobile .register-form input {
    width: 100%;
}

.mobile .sign-in-form .error, .mobile .register-form .error, .mobile .reset-password-form .error {
    background-color: unset;
    font-size: 1rem;
    padding: 10px;
    border: 1px solid #414345;
    border-radius: 4px;
}
.mobile .sign-in-form .error span, .mobile .register-form .error span, .mobile .reset-password-form .error span { color: rgb(255, 96, 96); }

.mobile .reset-password-form h5 { line-height: 1.5rem; }

.mobile .sign-in-form.border.rounded, .mobile .register-form.border.rounded, .mobile .reset-password-form.border.rounded {
    border-color: #414345!important;
}