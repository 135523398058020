/* set default */
html, body {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    line-height: 1.5rem;
    background-color: #171a26!important;
}

input[type="text"]:disabled {
    background: #ccc;
}

.app-intro {
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: #acacac;
    background-color: #171a26;
}
.app-intro .app-intro-text {
    font-size: 1.5rem;
    position: fixed;
    width: 300px;
    height: 80px;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -150px;
    text-align: center;
}

.display-none {display: none;}

.moving-cart {
    position: relative;
    left: 100px;
    animation-name: moving-cart;
    animation-duration: 7s;
}
  
@keyframes moving-cart {
    from {left: -100px; opacity: 0.2;}
    to {left: 100px; opacity: 1;}
}


.mobile {
    background-color: #171a26;
    color: #acacac;
    padding-bottom: 4rem;
}

.mobile .mobile-home {
    background-color: #171a26;
}

.mobile a {
    color: #acacac;
    text-decoration: none;
}
.mobile a.active { color: blue; }
.mobile a:hover { color: #a9d3ff; }

.mobile button.btn-primary {
    width: 100%;
    max-width: unset;
}

.mobile .mobile-header {
    background-color: #171a26;
}
.mobile .mobile-header a {
    color: #FFD700;
}

.mobile .mobile-footer {
    position: fixed;
    z-index: 2;
    left: 0;
    bottom: 0;
    width: 100%;
    color: #acacac;
    text-align: center;
    padding: 8px 0;
    border-top: 1px solid #D4AF37;
    background-color: #171a26;
}
.mobile .mobile-footer i {
    font-size: 1.5rem;
}
.mobile .mobile-footer .profile-pic {
    width: 1.5rem;
    height: 1.5rem;
}
.mobile .mobile-footer a {
    color: #D4AF37;
}
.mobile .mobile-footer a.active {
    color: #fff2ac;
}

.mobile .mobile-home .carousel {
    width: 100%;
    padding: 0 20px;
}
.mobile .mobile-home img {
    width: 100%;
}

.mobile .smaller-font-size {
    font-size: 0.7em;
}


.btn-grad {background-image: linear-gradient(#414345 0%, #232526  51%, #414345  100%)}
.btn-grad {
   padding: 15px 7px;
   text-align: center;
   transition: 0.5s;
   background-size: 200% auto;
   color: #acacac;            
   border-radius: 10px;
   border: 1px solid #414345;
   white-space: nowrap;
   margin-left: 8px;
 }
 .btn-grad:first-child { margin-left: 0; }

 .btn-grad:hover {
   background-position: right center; /* change the direction of the change here */
   color: #a9d3ff;
   text-decoration: none;
 }

.mobile .mobile-product-list .prod-list-container .prod-link,
.mobile .mobile-service-list .serv-list-container .serv-link { margin-left: 15px; }

.mobile .mobile-product-list .prod-list-container .prod-link:first-child,
.mobile .mobile-service-list .serv-list-container .serv-link:first-child { margin-left: 0; }

.mobile .mobile-product-list .prod-list-container .product-image,
.mobile .mobile-service-list .serv-list-container .service-image {
    background-color: #fff;
}
.mobile .mobile-product-list .prod-list-container .product-container .product-details,
.mobile .mobile-service-list .serv-list-container .service-container .service-details {
    background-image: linear-gradient(#414345 0%, #232526  51%, #414345  100%);
    padding: 8px;
    transition: 0.5s;
    background-size: 200% auto;
    border: 1px solid #414345;
    min-width: 200px;
}
.mobile .mobile-product-list .prod-list-container .product-image,
.mobile .mobile-service-list .serv-list-container .service-image {
    width: 100px;
    height: 100px;
}
.mobile .mobile-product-list .prod-list-container .product-details .seller-rating,
.mobile .mobile-service-list .serv-list-container .service-details .seller-rating {
    color: orange;
}


.mobile-modal-content .modal-content { border: 1px solid #414345; }
.mobile-modal-header, .mobile-modal-body {
    border-bottom: 1px solid #414345;
    background-color: #171a26;
}
.mobile-modal-header { padding: 0.5rem 1rem; }
.mobile-modal-header button {
    color: white;
    background: unset;
    font-size: 1.5rem;
}
.mobile-modal-header button:hover { color: skyblue; }

.mobile-modal-body .btn-grad {
    padding: 7px 10px;
    border-radius: 7px;
}
.mobile-modal-body .search .btn-group, .mobile-modal-body .btn-categories.btn-grad {
    width: 100%;
}
.mobile-modal-body .btn-search.btn-grad {
    font-size: 0.8rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.mobile-modal-body .dropdown-menu {
    width: 100%;
}
.mobile-modal-body .dropdown-menu:hover {
    cursor: pointer;
}



.mobile .success-message {
    background-color: unset;
    border: 1px solid #414345;
    font-size: 1rem;
    padding: 10px;
    border-radius: 4px;
    color:rgb(58, 156, 255);
}



.mobile-container {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.mobile .search a.prod-link {
    border: 0;
}
.mobile .search .product {
    border: 1px solid #414345;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    max-width: 250px;
}
.mobile .search .product:hover {
    border: #a9d3ff 1px solid;
}
.mobile .search .product img {
    background-color: white;
    width: 100%;
    max-width: 230px;
}

.mobile .product-thumb {
    width: 40px;
    height: 40px;
}

@media (max-width: 576px) {
    .mobile .search .product {
        max-width: 250px;
    }
}
@media (max-width: 540px) {
    .mobile .search .product {
        max-width: 200px;
    }
}
@media (max-width: 445px) {
    .mobile .search .product {
        max-width: 180px;
    }
}
@media (max-width: 400px) {
    .mobile .search .product {
        max-width: 160px;
    }
}
@media (max-width: 355px) {
    .mobile .search .product {
        max-width: 140px;
    }
}




.hr-text {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: #171a26;
    text-align: center;
    height: 1.5em!important;
    opacity: 1;
  }
  .hr-text:before {
      content: '';
      background: linear-gradient(to right, rgba(255, 255, 255, 0.05), white, rgba(255,255,255,.05));
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 2px;
    }
    .hr-text:after {
      content: attr(data-content);
      position: relative;
      display: inline-block;
      padding: 0 .5em;
      line-height: 1.5em;
      color: #FFD700;
      font-size: 1.2rem;
      background-color: #171a26;
    }

.hr-products.hr-text:before {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.05), rgb(0, 119, 255), rgb(0, 119, 255), rgba(255,255,255,.05));
}
.hr-services.hr-text:before {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.05), red, red, rgba(255,255,255,.05));
}
.hr-looking-for.hr-text:before {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.05), rgb(0, 255, 0), rgb(0, 255, 0), rgba(255,255,255,.05));
}

.font-color-gold { color: #FFD700; }
.font-italic { font-style: italic!important; }
.font-size-point8rem { font-size: 0.8rem; }