.mobile .seller-form {
    background-color: #171a26;
    border: 1px solid #414345!important;
    width: 100%;
    max-width: 600px;
}

.mobile .user-order {
    background-color: #171a26;
    border: 1px solid #414345!important;
    width: 100%;
    max-width: 600px;
}

.mobile .seller-form { max-width: 700px; }
.mobile .user-order { max-width: 700px; }

.mobile .result {
    min-height: 100%;
    max-height: auto;
    width: 100%;
    background-color: #dadada;
    margin-top:0.5rem ;
     display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left; 
}
  
.mobile .product-image-preview {
    max-width: 150px;
    max-height: 150px;
    object-fit: cover;
    padding: 0.75rem;
}

.mobile .product-cover-preview {
    max-width: 150px;
    max-height: 150px;
    object-fit: cover;
    padding: 0.75rem;
}

.mobile .product-thumb {
    width: 40px;
    height: 40px;
}

.mobile .product-order-thumb {
    width: 20px;
    height: 20px;
}

.mobile .return-order-thumb {
    width: 27px;
    height: 27px;
}

.mobile .price-field {
    width: 400px !important;
}

.mobile .price-edit-field {
    width: 100px !important;
}

.mobile .status-pill {
    font-size: .575em;
    width:  3.6rem;
    margin-bottom: 3px;
}

.mobile .title-limit {
    display: block;
    width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.mobile .edit-title-limit {
    display: block;
    width: 400px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.mobile .popup-title-limit {
    display: block;
    width: 260px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.mobile .product-listing {
    font-size: .875em;
}

.mobile .edit-product-listing {
    font-size: .825em;
}

.mobile .small-text {
    font-size: .875em;
}

.mobile .action-button {
    width: 70px;
}
