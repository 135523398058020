
/* set default */
html, body {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 1.5rem;
    background-color: #f5f6f9;
    color: #585858;
}

@media (min-width: 1200px) {
    html, body {
        font-size: 18px;
    }
}

html, body, ul, ol, li, form, fieldset, legend {
    margin: 0;
    padding: 0;
}
fieldset, img { border: 0; }  
h1, h2, h3, h4, h5, h6, p { margin: 0; }
h1 { font-size: 2.5rem; }
h2 { font-size: 2.0rem; }
h3 { font-size: 1.6rem; }
h4 { font-size: 1.3rem; }
h5 { font-size: 1rem; }
h6 { font-size: 0.8rem; }
p { font-size: 1rem; }
li { list-style: none; }
a { color: black; text-decoration: none; }
a:hover { color: #ff3333 }

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.input-text {
    width: 100%;
    padding: 6px 12px;
    font-size: 1rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    outline: none;
}

.btn-mp-default {
    background-color: #ff3333;
    border-color: #ff3333;
    color: white;
}
.btn-mp-default:hover {
    background-color: #ff5050;
    color: white;
}
.btn-mp-secondary {
    background-color: #851919;
    border-color: #851919;
    color: white;
}
.btn-mp-secondary:hover {
    background-color: #8b4545;
    color: white;
}

.logo-text { white-space: nowrap; }

.error span {
    color: red;
}
.success-message {
    background-color: rgb(228, 241, 255);
    font-size: 0.8rem;
    padding: 4px 10px;
    border-radius: 4px;
    color:rgb(0, 128, 255);
}

.header {
    background-color: rgb(236, 246, 255);
}
.header .input-group>.input-group-append>.btn, .home-search .input-group>.input-group-append>.btn {
    font-size: 0.8rem;
    line-height: 1.5rem;
    background-color: #ff3333;
    border-color: #ff3333;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.nav a:hover { color: #ff3333 }
.header .search ul.dropdown-menu span.dropdown-item {
    cursor: pointer;
}
.header .user-greeting {
    line-height: 1.8rem;
    margin-right: 1rem;
}

.footer {
    background-color: black;
    color: white;
    padding-top: 1.5rem;
}
.footer a {
    font-size: 0.8rem;
    color: white;
}
.footer a:hover { color: #ff3333 }

.copyright {
    background-color: #282828;
    color: #c8c8c8;
    font-size: 0.7rem;
    text-align: center;
    margin-top: 1rem;
    padding-top: 0.35rem;
    padding-bottom: 0.35rem;
}

/* bootstrap fix */
.ml-1 { margin-left: 0.25rem; }
.ml-2 { margin-left: 0.5rem; }
.ml-3 { margin-left: 1rem; }
.ml-4 { margin-left: 1.5rem; }
.mr-1 { margin-right: 0.25rem; }
.mr-2 { margin-right: 0.5rem; }
.mr-3 { margin-right: 1rem; }
.mr-4 { margin-right: 1.5rem; }
.mt-3 { margin-top: 1rem; }
.bi-md { font-size: 1.5rem; }


@media (min-width: 576px) { /* Small devices (landscape phones, 576px and up) */
    .mt-sm-0 { margin-top: 0; }
    .ml-sm-0 { margin-left: 0; }
    .ml-sm-4 { margin-left: 1.5rem; }
}
@media (min-width: 768px) { /* Medium devices (tablets, 768px and up) */
    .mr-md-2 { margin-right: 0.5rem; }
}

/* bootstrap
0 - sets margin or padding to 0
1 - sets margin or padding to .25rem (4px if font-size is 16px)
2 - sets margin or padding to .5rem (8px if font-size is 16px)
3 - sets margin or padding to 1rem (16px if font-size is 16px)
4 - sets margin or padding to 1.5rem (24px if font-size is 16px)
5 - sets margin or padding to 3rem (48px if font-size is 16px)
auto - sets margin to auto
*/

/* remove bootstrap box shadow */
textarea:focus, textarea.form-control:focus, 
input.form-control:focus, input[type=text]:focus, input[type=password]:focus, input[type=email]:focus, input[type=number]:focus, 
[type=text].form-control:focus, [type=password].form-control:focus, [type=email].form-control:focus, [type=tel].form-control:focus,
[contenteditable].form-control:focus { box-shadow: none; }

.border-color-red {
    border-color: red;
}


.search a.prod-link {
    border: 0;
}
.search .product {
    border: rgb(161, 161, 161) 1px solid;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
}
.search .product:hover {
    border: #ff3333 1px solid;
}
.search .product img {
    width: 100%;
}
@media (min-width: 576px) { /* Small devices (landscape phones, 576px and up) */
    .search .product {
        max-width: 300px;
    }
}

