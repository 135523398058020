.mobile .product-details, .mobile .service-details {
    background-color: #171a26;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
}
.mobile .product-details .product-image-container { background-color: #171a26; }
.mobile .product-details .product-image {
    text-align: center;
    height: auto;
    border: unset;
    background: unset;
    background-color: white;
}
.mobile .product-details .product-image img { width: 100%; }
@media (min-width: 400px) {
    .mobile .product-details .product-image {
        height: auto;
    }
}
@media (min-width: 576px) { /* Small devices (landscape phones, 576px and up) */
    .mobile .product-details .product-image {
        width: auto;
        height: auto;
    }
}

.mobile .product-details .thumbnails-container {
    margin-top: 10px;
    width: auto;
}
.mobile .product-details .thumbnails-container a:hover {
    cursor: pointer;
}
.mobile .product-details .product-image-thumb {
    background-color: white;
    max-height: 80px;
    border: 1px solid #414345;
    margin: 0 2px;
}

.mobile .product-details .selling-price, .mobile .service-details .selling-price {
    color: #ff3333;
}

.mobile .product-details .btn-mp-secondary {
    width: 100%;
}

.mobile .product-details .prod-unavailable { text-align: center; }