

.mobile .user-form {
    background-color: #171a26;
    border: 1px solid #414345!important;
    width: 100%;
    max-width: 600px;
}
.mobile .user-form.border-primary {
    border-color: #0d6efd!important;
}
.mobile .user-form.border-danger {
    border-color: #dc3545!important;
}

.mobile .user-form input {
    width: 100%;
}

.mobile .user-form button {
    max-width: 150px;
}

.mobile .user-form .error {
    background-color: #171a26;
    border: 1px solid #414345;
    font-size: 1rem;
    padding: 10px;
    border-radius: 4px;
}
.mobile .user-form .error span { color: rgb(255, 96, 96); }

.mobile .user-profile .user-form { max-width: 700px; }
.mobile .user-profile .vertical-bar { border-left: solid 1px #eee; }
.mobile .user-profile .profile-picture a { color: white }
.mobile .user-profile .profile-picture p { font-size: 0.8rem; margin-top: 8px; }
.mobile .user-profile .fileSelected {
    color: cornflowerblue;
    word-wrap: break-word;
    max-width: 200px;
}
.mobile .user-profile .profile-picture img.user-profile-pic {
    max-width: 80px;
    border-radius: 10px;
}

.mobile .btn.add { color: #0d6efd; }
.mobile .btn.add:hover { color: #0b5ed7; }

.mobile .address-list .field-name {
    width: 50%;
    text-align: right;
}
.mobile .address-list .address {
    background-color: #171a26;
    border: 1px solid #414345;
    padding: 25px;
    border-radius: 10px;
    width: 100%;
}

.mobile .address-list .address .badge-default {
    background-color: limegreen;
    height: 1.2rem;
}
.mobile .address-list .address .badge-addr-type {
    background-color: gray;
    margin: 0 8px;
    height: 1.2rem;
}
.mobile .address-list .address .edit-button, .mobile .address-list .address .delete-button {
    font-size: 0.8rem;
    margin: 0 4px;
}

.mobile .shopping-cart .user-form, .mobile .user-checkout .user-form { max-width: 1300px; }

.mobile .shopping-cart .user-form .alert:last-child {
    margin-bottom: 0;
}
.mobile .shopping-cart .product-container, .mobile .wish-list .product-container, .mobile .favorites .service-container {
    position: relative;
}
.mobile .shopping-cart .btn-remove, .mobile .wish-list .btn-remove, .mobile .favorites .btn-remove {
    position: absolute;
    top: -8px;
    right: -8px;
    z-index: 2;
    padding: 2px 3px;
    background-color: #171a26;
    border: 1px solid #414345;
    color: #acacac;
}
.mobile .shopping-cart button.btn-remove:hover {
    border-color: #a9d3ff;
    color: #a9d3ff;
}
.mobile .wish-list button.btn-remove:hover {
    border-color: #4790ff;
    color: #0d6efd;
}
.mobile .favorites button.btn-remove:hover {
    border-color: #db5966;
    color: #dc3545;
}
.mobile .wish-list .btn-remove {
    border: 1px solid #0d6efd;
}
.mobile .favorites .btn-remove {
    border: 1px solid #dc3545;
}

.mobile .shopping-cart .user-form .btn-remove { right: 1rem; }

.mobile .shopping-cart .user-form h4 { display: inline; }
.mobile .shopping-cart .user-form a { color: #ff3333; }
.mobile .shopping-cart .user-form a.btn-light { color: black; }

.mobile .shopping-cart .alert.total-amount {
    border-color: white;
    color: white;
}
.mobile .shopping-cart .alert.total-amount span { font-weight: bold; }

.mobile .change-password .user-form { max-width: 620px; }
.mobile .change-password .user-form button { max-width: unset; }

.mobile .pass-strength { font-size: 0.8rem; }
.mobile .pass-strength.strong { color: rgb(0, 182, 0); }
.mobile .pass-strength.weak { color: rgb(255, 96, 96); }
.mobile .pass-not-match { font-size: 0.8rem; color: rgb(255, 96, 96); }

.mobile .change-password .user-form h5, .mobile .security-questions .user-form h5 { line-height: 1.5rem; }
.mobile .security-questions .user-form button { min-width: 150px; }

.mobile .payment-options .user-form input[type="radio"] { width: 18px; }

.mobile .user-checkout button.change {
    width: unset;
    font-size: 0.8rem;
    padding: 2px 5px 1px;
}
.mobile .user-checkout-change-address label { display: inline; }


@media (min-width: 768px) {
    .mobile .user-profile .form-fields-container { min-width: 400px; }
}


.mobile .user-menu a.menu-link {
    display: block;
    position: relative;
    font-size: 1.2rem;
    color: white;
    border-bottom: 1px solid #414345!important;
    padding: 20px 0;
}
.mobile .user-menu a.menu-link:hover {
    background-color: #1f2230;
    color: #a9d3ff;
}
.mobile .user-menu a.menu-link i {
    position: absolute;
    right: 0px;
}


.mobile .modal-content, .mobile-modal .modal-content {
    background-color: #171a26;
    border: 1px solid #414345;
    color: #acacac;
}
.mobile .modal-header, .mobile-modal .modal-header { border-bottom: 1px solid #414345; }
.mobile .modal-footer, .mobile-modal .modal-footer { border-top: 1px solid #414345; }


.mobile .shopping-cart div.alert, .mobile .wish-list div.alert, .mobile .user-checkout div.alert,
.mobile .user-purchase div.alert, .mobile .favorites div.alert, .mobile .book-service div.alert {
    background-image: linear-gradient(#414345 0%, #232526  51%, #414345  100%);
    background-size: 200% auto;
    border: 1px solid #414345;
}
.mobile .wish-list div.alert:hover { border-color: #0d6efd; color: #acacac; }
.mobile .favorites div.alert:hover { border-color: #dc3545; color: #acacac; }
.mobile .wish-list .product-container:last-child a div.alert {
    margin-bottom: 0;
}

.mobile .shopping-cart .sc-thumbnail { max-width: 50px; }

.mobile .shopping-cart .sc-price { color: white }

.mobile .shopping-cart .form-check-input { cursor: pointer; }

.mobile .shopping-cart .check-out a { width: 100%; color: white; }

.mobile .user-checkout div.alert, .mobile .book-service div.alert { color: white }

.mobile .shopping-cart .quantity { font-size: 0.9rem;  }
.mobile .shopping-cart .quantity input {
    max-width: 90px;
    padding: 3px 6px;
    font-size: 0.9rem;
    height: 1.4rem;
}
.font-size-09rem {
    font-size: 0.8rem;
    line-height: 1.2rem;
}
.color-white {
    color: white;
}

.mobile .legal-page {
    background-color: #171a26;
    border: 1px solid #414345!important;
    width: 100%;
    max-width: 600px;
}